<template>
  <UserLayout :title="title" :showBtn="showButton" :buttonText="buttonText">
    <div class="ov_wrapper">
      <CourseOverview
        :key="count"
        :isEdit="true"
        :token="authToken"
        :overviewContent="overviewContent"
        :urlObj="getURLList"
        :courseId="course_id"
        :courseTitle="courseTitle"
        :tags="tags"
        :tagOptions="tagOptions"
        :org="courseOrg"
        @updateCourseOverview="$emit('updateCourseOverview')"
      />
    </div>
  </UserLayout>
</template>

<script>
import UserLayout from "@/components/Layout/UserLayout.vue";
import { CourseOverview } from "didactica";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isLoading: true,
      title: "Course Overview",
      showButton: false,
      buttonText: "Save Changes",
      count: 0,
      tags: [],
      overviewStatus: false,
      defaultOverviewContent: {
        course_id: "",
        index: 1,
        short_description: "",
        info: [
          { id: 0, description: "", image: "" },
          { id: -1, description: "", image: "" },
          { id: -2, description: "", image: "" }
        ],
        prerequisite: "",
        audience: "",
        about: [{ id: 0, order: "1", description: "", image: "" }],
        assessment_and_awards: "",
        other_info: "",
        tags: [""]
      }
    };
  },
  components: {
    CourseOverview,
    UserLayout
  },
  created() {
    this.getCourseOverviewState();
    this.getCourseDetailData();
  },
  computed: {
    ...mapGetters([
      "allConfig",
      "getCourseOverview",
      "getCourseOverviewStatus",
      "getCourseDetail",
      "getTags"
    ]),
    authToken() {
      return `Bearer ${this.$keycloak.token}`;
    },
    course_id() {
      return this.$route.params.id;
    },
    getURLList() {
      return {
        UPLOAD_COURSES: {
          method: "post",
          url: `${this.allConfig.baseURL}/courses/upload-image/`
        },
        GET_COURSE_OVERVIEW: {
          method: "get",
          url: `${this.allConfig.baseURL}/courses/overview/${this.course_id}/live/`
        },
        UPDATE_COURSE_OVERVIEW: {
          method: "patch",
          url: `${this.allConfig.baseURL}/courses/overview/${this.course_id}/`
        },
        CREATE_COURSE_OVERVIEW: {
          method: "post",
          url: `${this.allConfig.baseURL}/courses/overview/`
        },
        GET_COURSE_OVERVIEW_STATUS: {
          method: "get",
          url: `${this.allConfig.baseURL}/courses/overview/${this.course_id}/status/`
        },
        GET_TAGS: {
          method: "get",
          url: `${this.allConfig.baseURL}/tags/live/?org=${this.courseOrg}`
        }
      };
    },
    overviewContent() {
      if (this.overviewStatus) {
        return this.getCourseOverview;
      } else {
        return this.defaultOverviewContent;
      }
    },

    courseTitle() {
      return this.getCourseDetail.title;
    },
    courseOrg() {
      return this.getCourseDetail.org;
    },
    tagOptions() {
      return this.getTags;
    }
  },
  methods: {
    getCourseOverviewState() {
      this.$store
        .dispatch("getCourseOverviewStatus", {
          course_id: this.course_id
        })
        .then(() => {
          this.overviewStatus = this.getCourseOverviewStatus.has_overview;

          if (this.overviewStatus) {
            this.getCourseOverviewData();
          }
        });
    },

    getCourseOverviewData() {
      this.$store
        .dispatch("getCourseOverview", {
          course_id: this.course_id
        })
        .then(() => {
          this.count++;
        });
    },

    getTagsData() {
      this.$store.dispatch("getTags", { org: this.courseOrg }).then(() => {
        this.count++;
      });
    },
    getCourseDetailData() {
      this.$store
        .dispatch("getCourseDetail", { course_id: this.course_id })
        .then(() => {
          this.getTagsData();
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.ov_wrapper {
  border-radius: 10px;
  width: 100%;
  margin: 0px !important;
  .overview_wrapper .ov-container {
    margin: 0px !important;
  }
}
</style>
